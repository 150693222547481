import React from 'react';
import {Alert, Button} from '@mui/material';
import {info} from "../../info/Info";
function MyAlert(props ) {

    const {isopen, setOpen} = props;

    console.log(isopen)
    return (
    <div style={{visibility: isopen ? 'visible':'hidden'}}>
        {isopen && (
        <Alert severity="info" style={{fontSize:'1.1rem', maxWidth:'82vw', marginTop:'1.4rem'}}
        action={
            <Button color="warning" size="medium" onClick={setOpen}>
                Close
            </Button>
        }>
            {info.About.chatbot.warning}
     
        </Alert>
        )}
    </div>
    );
};

export default MyAlert;
