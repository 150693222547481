
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaUserGraduate, FaBook, FaHighlighter, FaUser, FaMountain, FaJava, FaPlane, FaUniversity, FaSuitcase, FaChartLine, FaRobot } from "react-icons/fa";
import {Box} from "@mui/material";
import {info} from "../../info/Info";
import uuid from 'react-uuid';
import DownloadButton from "./DownloadButton"
import React from 'react';

const getIcon = (str) => {
    switch (str) {
        case 'FaUserGraduate': return <FaUserGraduate/>
        case 'FaBook': return <FaBook />
        case 'FaHighlighter': return <FaHighlighter />
        case 'FaMountain': return <FaMountain />
        case 'FaJava': return <FaJava />
        case 'FaPlane' : return <FaPlane />
        case 'FaUniversity' : return <FaUniversity />
        case 'FaSuitcase' : return <FaSuitcase />
        case 'FaChartLine' : return <FaChartLine />
        case 'FaRobot' : return <FaRobot />

        default: return <FaUser />
    }
}

const Timeline = () => {
    return (
        <VerticalTimeline>
        {
            info.About.timeline.map((entry, index) => {
                return (
                    <VerticalTimelineElement 
                        key={index}
                        contentStyle={{padding:'0rem 1.2rem', background: entry.style.boxColor, color: "#000000"}}
                        contentArrowStyle={{ borderRight: '9px solid  rgb(255, 255, 255)' }}
                        iconStyle={{ background: entry.style.circleColor}}
                        icon={getIcon(entry.icon)}
                    >
                        <Box>
                            <div style={{width:'100%', textAlign:'right', padding:0}}> <p style={{padding:'5px'}}> {entry.date} </p> </div>
                            <h4 style={{fontWeight:'bold', fontSize:'1.3rem', margin:0}}>{entry.title}</h4>
                            
                            {entry.description.map((item) => {
                                    return (
                                        item.length > 1 ? 
                                                <p key={uuid()}>
                                                <span style={{fontWeight:'bold'}}>
                                                   {item[0]}
                                                </span>
                                                {item[1]}
                                                </p>
                                            : 
                                            <p key={uuid()} style={{fontWeight:entry.style.boldDescription, color:'#1f1f1f', margin:0}}> {item[0]} </p>
                                    
                                    );
                                
                            })}
                            <h4 style={{fontWeight:'bold', fontSize:'1rem', margin:0, padding: entry.link ? '5px' : '20px 0px' }} >{entry.location}</h4>
                            {entry.link ?
                                <div style={{paddingBottom:'10px'}}>
                                    <DownloadButton key={uuid()} path={entry.link} name={'idk '} title={"View Live Demo"} /> 
                                </div>
                                :
                                <></>
                            }
                        </Box>

                    </VerticalTimelineElement>                
                );
            })
        }

        </VerticalTimeline>
    );
}

export default Timeline;