import React, { useState } from 'react';
import Profile from "./Profile";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Box} from "@mui/material";
import ChatPane from './ChatPane';
import axios from 'axios';
import {info} from "../../info/Info";

export default function Home() {

    const [chatMode, updateMode] = useState(false)
    
    const wakeLLM = () => {
        axios.post('https://chatbot-ljmusw3rya-wm.a.run.app/wake')
        .then(function (response) {
            console.log(response.data[0].response);
        })
        .catch(function (error) {
            console.log(error);
        })
    }

   return (
    <Box component={'main'} display={'flex'} flexDirection={{xs: 'column', md: 'column'}} alignItems={'center'} 
    justifyContent={'center'} minHeight={'calc(100vh - 175px)'} maxHeight={'calc(100vh - 175px)'}>
        
        {info.About.chatbot.active ?
            <>
                <ToggleButtonGroup
                    color={'info'}
                    value={chatMode}
                    exclusive
                    onChange={() => updateMode(!chatMode)}
                    // style={{opacity:1}}
                    aria-label="Platform"
                    style={{width: '90vw', paddingTop:'8px', paddingBottom:'5px', margin:0}}
                    >
                    <ToggleButton value={false} style={{width:'45vw'}}> Profile </ToggleButton>
                    <ToggleButton value={true} style={{width:'45vw'}} onClick={wakeLLM}>Try My Chat-Bot</ToggleButton>
            
                </ToggleButtonGroup>

                {chatMode ?
                    <ChatPane/>
                    :
                    <Profile/>
                }
            </>
            :
            <Profile/>
        }
        
    </Box>

   )
}