import React from 'react';
import Style from './Profile.module.scss';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import {Box, Typography} from "@mui/material";
import {info, theme} from "../../info/Info";
import Typed from 'react-typed';
import useWindowDimensions from '../WindowDim';
import uuid from 'react-uuid';

function Profile() {

   const { height, width } = useWindowDimensions();
   const fontsize = Math.min(height, width) > 500 ? '50px':'30px'
   return (
      <Box height='90vh'>
         <Box className={classNames(Style.avatar, Style.shadowed)} alt={'image of developer'} style={{background: info.gradient}} component={'img'} src={info.selfPortrait} width={{xs: '35vh', md: '39vh'}}
              height={{xs: '35vh', md: '39vh'}} marginTop={'1.2rem'}
              borderRadius={'60%'} p={'0.25rem'} mb={{xs: '1rem', sm: 0}} mr={{xs: 0, md: '2rem'}}/>
         <Typography  marginTop={'.9rem'} variant='h4' noWrap={true} alignContent={'left'} overflow={'visible'} fontWeight="bold" style={{fontSize:fontsize}} color={theme.textColor} >
            Hi, I'm&nbsp;
            <Typed  showCursor={false} strings={[`${info.firstName} ${info.lastName}`]} typeSpeed={20} style={{fontWeight:'bold', background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}/>
         </Typography>
         <Box style={{color:theme.textColor}}>
            <h1 style={{fontSize:`calc(${fontsize} - .6rem)`}}>{info.position}</h1>
            <Box component={'ul'} h1={'0.8rem'} width={'100%'} marginLeft={'3%'}>
               {info.miniBio.map((bio, index) => (
                   bio.pagesIndex ?
                   <a href={`#/papers/${bio.pagesIndex}`} key={uuid()}> <EmojiBullet key={uuid()} emoji={bio.emoji} text={bio.text}/></a>
                   :
                   <EmojiBullet key={uuid()} emoji={bio.emoji} text={bio.text}/>
               ))}
            </Box>
         </Box>
      </Box>
   )
}

export default Profile