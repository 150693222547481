import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    if (!message.trim()) {
      return;
    }
    actions.handleMessage(message)
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;