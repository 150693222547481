import React, { useState } from 'react';
// import {info} from "../../info/Info";
import {Box} from "@mui/material";
import Chatbot from 'react-chatbot-kit'
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import Config from './Config';
import chatbotTheme from './ChatPane.css'
import MyAlert from './Alert';
import useWindowDimensions from "../WindowDim"
import {info} from "../../info/Info";


function ChatPane(props) {
    const [open, setOpen] = useState(info.About.chatbot.warningActive);
    const { height, width } = useWindowDimensions();
    const big_screen = Math.min(height, width) > 500 ;
    
    return(
        <Box
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            justifyContent={open ? "top" : "center"}
            width="100vw"
            height="90vh"
            maxHeight={'90vh'}
            style={{ padding:'0px', overflow:'hidden'}}
        >       
            {!big_screen ?
                open ?
                    <MyAlert isopen={open} setOpen={() => {setOpen(false)}} />
                    :
                    
                    <Chatbot config={Config} actionProvider={ActionProvider} messageParser={MessageParser} theme={chatbotTheme} />
                :
                    <Chatbot config={Config} actionProvider={ActionProvider} messageParser={MessageParser} theme={chatbotTheme}/>
            }   
   
        </Box>
    )
}

export default ChatPane;

