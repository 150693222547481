// Config starter code
import { createChatBotMessage } from "react-chatbot-kit";

const Config = {
  initialMessages: [
    createChatBotMessage(`Justin built me to answer your questions 😊 What would you like to know?`), 
  ],  
  customComponents: {
    // Replaces the default header
   header: () => <div className="react-chatbot-kit-chat-header"> JustinQuiry Bot </div>,

 },
}

export default Config