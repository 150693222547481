import React from 'react';
import axios from 'axios';
import {info} from "../../info/Info";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const handleMessage = (message) => {

    if (!message.trim()) {
      return;
    }
    
    var body = {
      query: message,
      temperature: 0.2,
      max_output_tokens: 1000,
      top_p: 0.8,
      top_k: 40,
      similarity_score_threshold: 0.0,
    };

    axios.post(info.About.chatbot.endpoint, body)
      .then(function (response) {
        console.log(response);
        const botResponse = response.data[0].response;
        console.log(botResponse);

        const botMessage = createChatBotMessage(botResponse);

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      })
      .catch(function (error) {
        console.log(error);
        const errorMessage = "Please tell J-Dawg to fix my server. I'm struggling to talk to it successfully at the moment.";
        const errorBotMessage = createChatBotMessage(errorMessage);

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, errorBotMessage],
        }));
      });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
